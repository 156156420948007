/* global $ */

class App
{
    bootstrap;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initformBgImg();
        this.initMatrixBlock();
        this.initStickyMenu();
        this.initWhiteMenu();
        this.initSubmenu();
        this.initCountrySelect();
        this.initHederHeight();
        this.initCounter();
        this.initAos();
        this.fixFooterVideo();
        this.playVideo();
        //for popup, dont remove this
        this.openrequestaCallbackPopup();
        this.disposemodal();
        
        if ($('.article-search').length) {
            $('.article-search').css('top', $('.header-inner').height());
        }
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            this.initStickyMenu();
            $('.header-bg').css('background-position', 'center bottom ' + $(window).scrollTop() + 'px');
            $('.mobile-buttons').css('bottom', -80);
            $('.article-search').css({'top': 0, 'background-position': 'center bottom ' + $(window).scrollTop() + 'px'});
            $.data(this, 'scrollTimer', setTimeout(function() {
                $('.mobile-buttons').css('bottom', 0);
                $('.article-search').css('top', $('.header-inner').height());
                $('.header-bg').css('background-position', 'center bottom ' + $(window).scrollTop() + 'px');
            }, 1000));
        });
        $(window).resize(() => {
            this.initStickyMenu();
            this.fixFooterVideo();
            $('.mobile-buttons').css('bottom', 0);
            $('.article-search').css('top', $('.header-inner').height());
            $('.header-bg').css('background-position', 'center bottom ' + $(window).scrollTop() + 'px');
        });
        console.log('App initialized');
    }

    getCookie(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem);
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    initformBgImg()
    {
        $(".form-btn").click(function(){
            if($('.form-btn').hasClass( "active" )){
                var imgUrl = $(this).attr('data-image-url');
                $('#bgImage').html(imgUrl);
            }
        });

    }

    initMatrixBlock() {
        if ($('.matrix-block').length) {
            import(/* webPackChunkName: "matrixContent" */ './components/matrixblock');
        }
    }
    initAos() {
        $(function () {
            var AOS = require('aos');
            AOS.init({
                offset: 100,
                duration: 700,
                easing: "ease-out-quad",
            });
            window.addEventListener('load', AOS.refresh);
            document.querySelectorAll('img')
                .forEach((img) =>
                    img.addEventListener('load', () =>
                        AOS.refresh()
                    )
                );
        });
    }
    initCounter() {
        if ($('.online-presence').length) {
            let triggered = false;
            let scrollTop = $(window).scrollTop();
            let triggerHeight = $('.online-presence').offset().top - $(window).innerHeight() + 220;
            if (scrollTop > triggerHeight) {
                triggered = true;
                $('.counter').each(function() {
                    let $this = $(this),
                        countTo = $this.attr('data-count');
                    $({ countNum: $this.text()}).animate({
                        countNum: countTo
                    },
                    {
                        duration: 1600,
                        easing:'linear',
                        step: function() {
                            $this.text(Math.floor(this.countNum));
                        },
                        complete: function() {
                            $this.text(this.countNum);
                            triggered = false;
                        }
                    });
                });
            } else {
                $('.counter').each(function() {
                    let $this = $(this);
                    $this.text(0);
                });
            }
            $(window).scroll(() => {
                if (!triggered) {
                    scrollTop = $(window).scrollTop();
                    triggerHeight = $('.online-presence').offset().top - $(window).innerHeight() + 220;
                    if (scrollTop > triggerHeight) {
                        triggered = true;
                        $('.counter').each(function() {
                            let $this = $(this),
                                countTo = $this.attr('data-count');
                            $({ countNum: $this.text()}).animate({
                                countNum: countTo
                            },
                            {
                                duration: 1600,
                                easing:'linear',
                                step: function() {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function() {
                                    $this.text(this.countNum);
                                    triggered = false;
                                }
                            });
                        });
                    } else {
                        $('.counter').each(function() {
                            triggered = false;
                            let $this = $(this);
                            $this.text(0);
                        });
                    }
                }
            });
        }
    }

    initStickyMenu() {
        let scrollTop = $(window).scrollTop();
        if ($(".header-banner").length > 0 && !$('body').hasClass('home')) {
            let bannerHeight = $(".header-banner").outerHeight();
            let headerHeight = $(".header-inner").outerHeight();
            $('.page-heading-height').height($('.page-heading').height());
            if (scrollTop > (bannerHeight - headerHeight + 40) && $(window).width() > 767) {
                $('.page-heading').addClass('scrolled').css({'top': headerHeight - 40, 'background-position': 'center bottom ' + $(window).scrollTop() + 'px'});
                $('.page-heading-bg').height(scrollTop - (bannerHeight - headerHeight)).css({'background-position': 'center bottom ' + $(window).scrollTop() + 'px'});
            } else if (scrollTop > (bannerHeight - headerHeight + 20) && $(window).width() < 768) {
                $('.page-heading').addClass('scrolled').css({'top': headerHeight - 20, 'background-position': 'center bottom ' + $(window).scrollTop() + 'px'});
                if (scrollTop > bannerHeight) {
                    $('.page-heading-bg').height(136).removeClass('position-absolute').addClass('position-fixed').css({'background-position': 'center bottom ' + $(window).scrollTop() + 'px'});
                } else {
                    $('.page-heading-bg').height(scrollTop - (bannerHeight - headerHeight) + 55).removeClass('position-fixed').addClass('position-absolute').css({'background-position': 'center bottom ' + $(window).scrollTop() + 'px'});
                }
            } else if ($(window).width() < 768) {
                $('.page-heading').removeClass('scrolled').css({'top': 'auto'});
                $('.page-heading-bg').height(75).removeClass('position-fixed').addClass('position-absolute');
            } else {
                $('.page-heading').removeClass('scrolled').css({'top': 'auto'});
                $('.page-heading-bg').height(0);
            }
        } else if ($(".header-banner").length > 0 && $('body').hasClass('home')) {
            let bannerHeight = $(".header-banner").outerHeight();
            if (scrollTop > (bannerHeight - 200)) {
                $('#header').addClass('scrolled');
            } else {
                $('#header').removeClass('scrolled');
            }
        } else {
            $('#header').addClass('scrolled');
        }
    }
    initWhiteMenu() {
        $('.search-toggle').click(() => {
            if ($('.search-toggle').hasClass('collapsed')) {
                $('#header').removeClass('light-header');
            } else {
                $('#header').addClass('light-header');
                $('.navbar-toggler:not(.collapsed)').addClass('collapsed');
                $('#mainNav').animate({height:0},350, function() {
                    $(this).removeClass('show').css('display', '');
                });
            }
        });
        $('.navbar-toggler').click(() => {
            if ($('.navbar-toggler').hasClass('collapsed')) {
                $('#header').removeClass('light-header');
            } else {
                $('#header').addClass('light-header');
                $('.search-toggle:not(.collapsed)').addClass('collapsed');
                $('#searchbox').animate({height:0},350, function() {
                    $(this).removeClass('show').css('display', '');
                });
            }
        });
    }

    initSubmenu() {
        $('.top-nav-link').each(function() {
            $('.navbar-toggler').click(() => {
                if (!$('.navbar-toggler').hasClass('collapsed')) {
                    if ($(this).next().find('a.nav-link.active').length !== 0) {
                        $('.top-nav-link').not(this).removeClass('menu-active');
                        $(this).addClass('menu-active');
                        let target = '.' + $(this).data('menu-target');
                        $('.submenu').removeClass('show');
                        $(target).addClass('show');
                    }
                }
            });
            $(this).hover(() => {
                $('.top-nav-link').not(this).removeClass('menu-active');
                $(this).addClass('menu-active');
                let target = '.' + $(this).data('menu-target');
                $('.submenu').removeClass('show');
                $(target).addClass('show');
            });
        });
    }

    initHederHeight() {
        let headerheight = $('.header-inner').innerHeight();
        $('.header-height').height(headerheight);
        if ($(window).width() < 992) {
            $('.mobile-nav-container').height($(window).innerHeight() - 183);
        }
    }

    initArticleSearch() {
        if ($('.article-search').length) {
            $('.article-search').css('background-position', 'center bottom ' + $(window).scrollTop() + 'px');
        }
    }

    initCountrySelect() {
        $('.current-country .country-btn').click(() => {
            $('.country-options').toggleClass('show');
        });
    }

    fixFooterVideo() {
        if ($('.footer-embed').length) {
            let footerW = $(window).innerWidth();
            let footerH = $('footer').height();
            if (footerW / footerH >= 16 / 9) {
                $('.footer-embed').width($(window).innerWidth()).height($(window).innerWidth() * 9 / 16);
            } else {
                $('.footer-embed').height(footerH).width(footerH * 16 / 9);
            }
        }
    }
    playVideo() {
        if ($('.popup-video-play').length) {
            $('.popup-video-play').each(function() {
                $(this).click(() => {
                    let videoFrame = $('#popupVideo').find('iframe');
                    let videoUrl = $(this).data('video');
                    let videoTitle = $(this).data('video-title');
                    videoFrame.attr({'src': videoUrl, 'title': videoTitle});
                });
                let removeVideo = $('#popupVideo').find('.btn-close');
                removeVideo.click(() => {
                    let videoFrame = $('#popupVideo').find('iframe');
                    videoFrame.removeAttr('src title');
                });
            });
        }
    }

    openrequestaCallbackPopup() {
        // var delayMillis = 30 * 1000;
        var _this = this;
        // Set a timeout to show the popup after the specified delay
        console.log('popup');
        if ((!$('.uk-pension-transfer-test').length) && (!$('.retirement').length)) {
            if (!this.getCookie('requestpopup') || (this.getCookie('requestpopup') != 'yes')) {
                setTimeout(function() {
                    _this.getBootstrap().then((bootstrap) => {
                        var myModal = new bootstrap.Modal(document.getElementById('enqmodal'), {
                            keyboard: false
                        });
                        myModal.show();
                    });
                    setInterval(function() {
                        if (!$('#enqmodal').hasClass('show')) {
                            _this.getBootstrap().then((bootstrap) => {
                                var myModal = new bootstrap.Modal(document.getElementById('enqmodal'), {
                                    keyboard: false
                                });
                                myModal.show();
                            });
                        }
                    }, 3600000);
                }, 30000);
                //_this.disposemodal();
            }
        }
    }

    disposemodal() {
        $('.callback-close').on('click', function(){
            // This code will execute after 2 seconds
            // _this.openrequestaCallbackPopup();
            var date = new Date();
            date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
            // date.setTime(date.getTime() + (60 * 60 * 1000));
            var expires = "expires=" + date.toUTCString();
            document.cookie = 'requestpopup' + "=" + 'yes' + "; " + expires + "; path=/";
        })
    }
}

export default App;